import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import RentaVariable from "./components/RentaVariable";
import RentaFija from "./components/RentaFija"; // Importar el componente RentaFija
import "./App.css";

function App() {
  return (
    <Router>
      <div className="app-container">
        <Routes>
          <Route exact path="/" element={<RentaVariable />} />
          <Route path="/variable" element={<RentaVariable />} />
          <Route path="/fija" element={<RentaFija />} />
          <Route path="*" element={<Navigate to="/variable" />} />{" "}
          {/* Redirigir rutas no especificadas */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
