import React, { useState } from "react";
import "./RentaVariable.css"; // Importar estilos CSS personalizados
import DatePicker from "react-datepicker"; // Importar DatePicker desde react-datepicker
import "react-datepicker/dist/react-datepicker.css"; // Importar estilos CSS de react-datepicker
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { PDFDocument, StandardFonts } from "pdf-lib";
import logo from "../assets/wt.png";

// Establecer el idioma predeterminado a español
registerLocale("es", es);
setDefaultLocale("es");

const RentaVariable = () => {
  const [startDate, setStartDate] = useState(new Date()); // Estado para almacenar la fecha seleccionada
  const [name, setName] = useState("");
  const [documentType, setDocumentType] = useState("V"); // Estado para el tipo de documento (V, E, J)
  const [documentNumber, setDocumentNumber] = useState(""); // Estado para el número de documento
  const [items, setItems] = useState([]);

  // Función para agregar un nuevo ítem al array de ítems
  const addItem = () => {
    const newItem = {
      id: Date.now(), // Generar un ID único basado en la marca de tiempo
      issuer: "",
      quantity: "",
      price: "",
    };
    setItems([...items, newItem]);
  };

  // Función para eliminar un ítem del array de ítems
  const removeItem = (id) => {
    const updatedItems = items.filter((item) => item.id !== id);
    setItems(updatedItems);
  };

  // Función para manejar cambios en el nombre
  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
  };

  // Función para manejar cambios en el número de documento
  const handleDocumentNumberChange = (e) => {
    const value = e.target.value;
    // Validar solo números para el documento
    if (/^\d*$/.test(value)) {
      setDocumentNumber(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      date: startDate.toLocaleDateString("es-ES"),
      name: name,
      ci: `${documentType}${documentNumber}`,
      items: items,
    };

    try {
      // Generar el PDF con los datos del formulario
      const pdfDoc = await PDFDocument.create();
      const page = pdfDoc.addPage([600, 900]);

      const font = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
      const leftTextFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
      const leftTextFontSize = 12;

      // Añadir la fecha en la parte superior derecha
      const cityAndDate = `Caracas, ${formData.date}`;
      const cityAndDateWidth = font.widthOfTextAtSize(
        cityAndDate,
        leftTextFontSize
      );
      const cityAndDateX = 600 - 50 - cityAndDateWidth;

      page.drawText(cityAndDate, {
        x: cityAndDateX,
        y: 900 - 50,
        size: leftTextFontSize,
        font: font,
      });

      // Añadir el texto alineado a la izquierda
      const leftText = `
  
  Señores
  World Trading Casa de Bolsa, C.A.
  Presente.-
  
  Estimados Señores:
  `;

      const lines = leftText.trim().split("\n");
      let currentHeight = 900 - 50 - 30; // Ajustar la posición vertical inicial

      lines.forEach((line) => {
        page.drawText(line.trim(), {
          x: 50,
          y: currentHeight,
          size: leftTextFontSize,
          font: font,
        });
        currentHeight -= leftTextFontSize + 5; // Ajustar el espaciado entre líneas
      });

      // Añadir el texto desde "Quien suscribe" hasta "Renta Variable"
      const mainText = `Quien suscribe, ${formData.name}, venezolano, titular de la cédula de identidad / RIF ${formData.ci}, autorizo a World Trading Casa de Bolsa, C.A. RIF J-41253655-9 a COMPRAR los siguientes títulos por emisores de Renta Variable:`;

      // Dividir el texto en líneas que se ajusten al ancho de la página
      const mainTextLines = mainText.trim().split(" ");
      let currentLine = "";

      mainTextLines.forEach((word) => {
        const testLine = currentLine + word + " ";
        const testLineWidth = leftTextFont.widthOfTextAtSize(
          testLine,
          leftTextFontSize
        );

        if (testLineWidth > 500) {
          page.drawText(currentLine.trim(), {
            x: 50,
            y: currentHeight,
            size: leftTextFontSize,
            font: leftTextFont,
          });
          currentLine = word + " ";
          currentHeight -= leftTextFontSize + 5; // Ajustar el espaciado entre líneas
        } else {
          currentLine = testLine;
        }
      });

      // Dibujar la última línea
      if (currentLine) {
        page.drawText(currentLine.trim(), {
          x: 50,
          y: currentHeight,
          size: leftTextFontSize,
          font: leftTextFont,
        });
        currentHeight -= leftTextFontSize + 5; // Ajustar el espaciado entre líneas
      }

      // Añadir la primera tabla
      currentHeight -= 20; // Espacio antes de la primera tabla
      const tableHeaders = ["Emisor", "Cantidad", "Precio"];
      const headerX = [50, 250, 450]; // Posiciones X para los encabezados de la tabla
      const headerY = currentHeight;

      // Dibujar los encabezados de la tabla
      tableHeaders.forEach((header, index) => {
        page.drawText(header, {
          x: headerX[index],
          y: headerY,
          size: leftTextFontSize,
          font: font,
        });
      });

      currentHeight -= leftTextFontSize + 5; // Ajustar el espaciado después de los encabezados

      // Dibujar los datos de la tabla
      formData.items.forEach((item) => {
        page.drawText(item.issuer, {
          x: headerX[0],
          y: currentHeight,
          size: leftTextFontSize,
          font: leftTextFont,
        });
        page.drawText(item.quantity, {
          x: headerX[1],
          y: currentHeight,
          size: leftTextFontSize,
          font: leftTextFont,
        });
        page.drawText(item.price, {
          x: headerX[2],
          y: currentHeight,
          size: leftTextFontSize,
          font: leftTextFont,
        });
        currentHeight -= leftTextFontSize + 5; // Ajustar el espaciado entre filas
      });

      // Espacio adicional entre la primera tabla y el texto posterior
      currentHeight -= 40;

      // Agregar el texto posterior a la primera tabla
      const afterTableText = `Luego de que se realice la compra de las acciones, mantener en custodia las mismas en Caja Venezolana de valores hasta que por vía escrita instruya lo contrario.`;

      // Dividir el texto en líneas que se ajusten al ancho de la página
      const afterTableLines = afterTableText.trim().split(" ");
      let currentLine2 = "";

      afterTableLines.forEach((word) => {
        const testLine = currentLine2 + word + " ";
        const testLineWidth = leftTextFont.widthOfTextAtSize(
          testLine,
          leftTextFontSize
        );

        if (testLineWidth > 500) {
          // Ajusta el ancho de acuerdo a tu margen
          page.drawText(currentLine2.trim(), {
            x: 50,
            y: currentHeight,
            size: leftTextFontSize,
            font: leftTextFont,
          });
          currentLine2 = word + " ";
          currentHeight -= leftTextFontSize + 5; // Ajustar el espaciado entre líneas
        } else {
          currentLine2 = testLine;
        }
      });

      // Dibujar la última línea
      if (currentLine2) {
        page.drawText(currentLine2.trim(), {
          x: 50,
          y: currentHeight,
          size: leftTextFontSize,
          font: leftTextFont,
        });
        currentHeight -= leftTextFontSize + 5; // Ajustar el espaciado entre líneas
      }

      currentHeight -= 20; // Espacio antes de la línea de firma

      const originDeclarationData = [
        ["Declaración Jurada de Origen y Destino de los Fondos:"],
        [
          "En cumplimiento a lo establecido en la Providencia Nro. 209-2020, de fecha 10 de diciembre de 2020 y publicada en la Gaceta Oficial N° 42.115 del 28 de Abril 2021, donde se dictan las “Normas relativas a la Administración y Fiscalización de los riesgos relacionados con los delitos de la Legitimación de Capitales y Financiamiento al Terrorismo, Financiamiento a la Proliferación de Armas de Destrucción Masiva y otros Ilícitos, Aplicables a los sujetos Regulados por la Superintendencia Nacional de Valores”, por esta vía declaro bajo fe de juramento que los dineros, capitales, haberes,valores o títulos utilizados en las operaciones realizadas con World Trading Casa de Bolsa, C.A. no tiene relación con actividades producto de los delitos previstos en la Ley Orgánica contra la Delincuencia Organizada y Financiamiento al Terrorismo (LOCDOFT) asi como tampoco en los artículos de la Ley orgánica de Drogas, así como en cualquier otro establecido en la Legislación penal vigente.",
        ],
      ];

      const headerX2 = [50, 250, 450]; // Posiciones X para los encabezados de la tabla
      let headerY2 = currentHeight;

      // Dibujar los encabezados de la tabla
      originDeclarationData.forEach((row, rowIndex) => {
        let y = headerY2 - rowIndex * (leftTextFontSize + 5);

        row.forEach((cell, index) => {
          if (index === 0) {
            // Ajustar el texto largo para que no se desborde del margen derecho
            const maxLineWidth = 500; // Ancho máximo permitido
            const words = cell.split(" ");
            let line = "";
            let currentX = headerX2[index];
            let currentY = y;

            words.forEach((word) => {
              const testLine = line + word + " ";
              const testLineWidth = leftTextFont.widthOfTextAtSize(
                testLine,
                leftTextFontSize
              );

              if (testLineWidth > maxLineWidth) {
                // Dibujar la línea actual
                page.drawText(line.trim(), {
                  x: currentX,
                  y: currentY,
                  size: leftTextFontSize,
                  font: leftTextFont,
                });
                currentY -= leftTextFontSize + 5; // Ajustar el espaciado entre líneas
                line = word + " ";
              } else {
                line = testLine;
              }
            });

            // Dibujar la última línea si es necesario
            page.drawText(line.trim(), {
              x: currentX,
              y: currentY,
              size: leftTextFontSize,
              font: leftTextFont,
            });
          } else {
            // Dibujar las otras celdas normalmente
            page.drawText(cell, {
              x: headerX2[index],
              y: y,
              size: leftTextFontSize,
              font: leftTextFont,
            });
          }
        });

        // Ajustar la posición Y para la siguiente fila
        headerY2 -= leftTextFontSize + 5;
      });

      currentHeight -= 250; // Espacio adicional entre la segunda tabla y el texto final

      // Añadir el texto final
      const finalText = `Sin otro particular al cual hacer referencia, se suscribe.`;
      const finalLines = finalText.trim().split("\n");

      finalLines.forEach((line) => {
        page.drawText(line, {
          x: 50,
          y: currentHeight,
          size: leftTextFontSize,
          font: leftTextFont,
        });
        currentHeight -= leftTextFontSize + 5; // Ajustar el espaciado entre líneas
      });

      // Espacio antes de la firma
      currentHeight -= 20;

      const finalTextA = `Atentamente,`;
      const finalLinesA = finalTextA.trim().split("\n");

      finalLinesA.forEach((line) => {
        page.drawText(line, {
          x: 50,
          y: currentHeight,
          size: leftTextFontSize,
          font: leftTextFont,
        });
        currentHeight -= leftTextFontSize + 5; // Ajustar el espaciado entre líneas
      });

      // Añadir la línea para la firma
      currentHeight -= 20; // Espacio antes de la línea de firma

      // Añadir el nombre y la cédula
      const signatureText = `${formData.name}\nCédula de Identidad: ${formData.ci}`;
      const signatureLines = signatureText.split("\n");

      signatureLines.forEach((line) => {
        page.drawText(line, {
          x: 50,
          y: currentHeight,
          size: leftTextFontSize,
          font: leftTextFont,
        });
        currentHeight -= leftTextFontSize + 5; // Ajustar el espaciado entre líneas
      });

      // Guardar el documento y retornar los bytes del PDF
      const pdfBytes = await pdfDoc.save();

      // Descargar el PDF generado
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "document.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  // Función para manejar cambios en los ítems
  const handleItemChange = (e, index, field) => {
    const value = e.target.value;
    const updatedItems = [...items];
    updatedItems[index][field] = value;
    setItems(updatedItems);
  };

  return (
    <div className="form-container">
      <div className="logo-container">
        <img src={logo} alt="Logo de la empresa" className="logo-image" />
      </div>
      <h1>Renta Variable</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-3 input-space">
          <label className="form-label">Fecha:</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="dd/MM/yyyy"
            className="form-input"
            required
          />
        </div>
        <div className="mb-3 input-space">
          <label className="form-label">Nombre:</label>
          <input
            type="text"
            className="form-input"
            value={name}
            onChange={handleNameChange}
            required
          />
        </div>
        <div className="mb-3 input-space">
          <label className="form-label">Tipo de documento:</label>
          <select
            value={documentType}
            onChange={(e) => setDocumentType(e.target.value)}
            className="form-input"
          >
            <option value="V">V</option>
            <option value="E">E</option>
            <option value="J">J</option>
          </select>
        </div>
        <div className="mb-3 input-space">
          <label className="form-label">Número de documento:</label>
          <input
            type="text"
            className="form-input"
            value={documentNumber}
            onChange={handleDocumentNumberChange}
            required
          />
        </div>
        <div className="container-divider"></div>
        <div className="container-button">
          {items.map((item, index) => (
            <div className="form-group" key={item.id}>
              <div className="group-title">
                <label className="form-label">Emisor:</label>
              </div>
              <div className="form-group-content">
                <input
                  type="text"
                  className="form-input"
                  value={item.issuer}
                  onChange={(e) => handleItemChange(e, index, "issuer")}
                  //pattern="[A-Za-z\u00C0-\u00FF ]+"
                  required
                />
              </div>
              <div className="group-title">
                <label className="form-label">Cantidad:</label>
              </div>
              <div className="form-group-content">
                <input
                  type="number"
                  step="0.01"
                  className="form-input"
                  value={item.quantity}
                  onChange={(e) => handleItemChange(e, index, "quantity")}
                  required
                />
              </div>
              <div className="group-title">
                <label className="form-label">Precio:</label>
              </div>
              <div className="form-group-content">
                <input
                  type="number"
                  step="0.01"
                  className="form-input"
                  value={item.price}
                  onChange={(e) => handleItemChange(e, index, "price")}
                  required
                />
              </div>
              <div className="divider"></div> {/* Línea divisoria */}
              <button
                type="button"
                className="form-button-red"
                onClick={() => removeItem(item.id)}
              >
                Eliminar
              </button>
            </div>
          ))}
          <button
            type="button"
            className="form-button-green mb-3"
            onClick={addItem}
          >
            Agregar
          </button>
          <button type="submit" className="form-button">
            Enviar
          </button>
        </div>
      </form>
    </div>
  );
};

export default RentaVariable;
